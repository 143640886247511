import React from 'react';
import { getEnvironment } from '../../helper/environment';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { ActionsController } from './ActionsController';
import { getElementColor } from '../helper';
import { useFunnelColorTheme } from '../../redux/slices/mainData';
import { FUNNEL_THEME_KEYS } from '../../redux/interfaces/mainData';
interface PropTypes {
  align?: string;
  maxHeight?: string;
  url?: string;
  backgroundColor?: string;
}

const LogoComponent = ({
  align,
  url,
  backgroundColor,
  maxHeight,
}: PropTypes): React.ReactElement => {
  const size = { maxHeight, maxWidth: '480px' };
  const { defaultDomain } = getEnvironment();
  const src = url?.includes('default-logo.png') ? defaultDomain + url : url;
  const theme = useFunnelColorTheme();
  return (
    <ActionsController
      className="p-4"
      style={{ textAlign: align, background: getElementColor(backgroundColor, theme) }}
    >
      <img src={src} style={size} />
    </ActionsController>
  );
};

const LogoComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
  align: 'center',
  url: '/dashboard/funnel-builder/default-logo.png',
};

LogoComponent.craft = {
  name: CRAFT_ELEMENTS.LOGO,
  props: LogoComponentDefaultProps,
};

export default LogoComponent;

import React from 'react';
import { ActionsController } from './ActionsController';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { FUNNEL_THEME_KEYS } from '../../redux/interfaces/mainData';
import {
  addOpacityToLinearGradient,
  convertColorToRGBA,
  getContainerFinalSectionId,
  getElementColor,
} from '../helper';
import useWindowDimensions from './hooks/useWindowDimensions';
import { useFunnelColorTheme } from '../../redux/slices/mainData';

const defaultContainerMinHeightForMobile = '0px';
const defaultContainerMinHeightForDesktop = 'auto';

interface Props {
  backgroundColor?: string;
  paddingTopClass: string;
  paddingBottomClass: string;
  children?: any;
  nodeId: string;
  parentNodeId?: string;
  sectionId?: string;
  backgroundImg?: string;
  backgroundImgEnabled?: boolean;
  opacity?: string;
  containerMinHeightForMobile?: string;
  containerMinHeightDesktop?: string;
  align: string;
  containerBackgroundAlignment?: string;
}

const ContainerComponent = ({
  backgroundColor,
  paddingTopClass,
  paddingBottomClass,
  children,
  nodeId,
  backgroundImgEnabled,
  opacity,
  containerMinHeightForMobile,
  containerMinHeightDesktop,
  backgroundImg,
  containerBackgroundAlignment,
  ...props
}: Props): React.ReactElement => {
  const { width } = useWindowDimensions();
  const isDesktop = width >= 576;
  const theme = useFunnelColorTheme();
  const getContainerMinHeight = isDesktop
    ? backgroundImgEnabled
      ? containerMinHeightDesktop
      : defaultContainerMinHeightForDesktop
    : backgroundImgEnabled
    ? containerMinHeightForMobile
    : defaultContainerMinHeightForMobile;
  const actualBackgroundColor = getElementColor(backgroundColor, theme);
  // defining the function here because have to use getElementColor from craftjs
  const makeGradientForBackgroundImage = (clr: string): string => {
    if (clr.toLowerCase().includes('gradient')) {
      return addOpacityToLinearGradient(getElementColor(clr, theme), parseFloat(opacity as string));
    } else {
      const newColor = convertColorToRGBA(getElementColor(clr, theme), opacity);
      return `linear-gradient(to right, ${newColor}, ${newColor})`;
    }
  };

  const newBackground = makeGradientForBackgroundImage(backgroundColor as string);
  const wrapperStyles =
    backgroundImgEnabled && backgroundImg
      ? {
          backgroundImage: `${newBackground}, url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: `${containerBackgroundAlignment ?? 'center'} center`,
          backgroundRepeat: 'no-repeat',
          minHeight: getContainerMinHeight,
        }
      : {
          background: actualBackgroundColor,
          minHeight: getContainerMinHeight,
        };

  const innerStyles = {
    minHeight: 'inherit',
  };

  return (
    <ActionsController
      {...props}
      className={`container__wrapper ${paddingTopClass} ${paddingBottomClass}`}
      style={wrapperStyles}
    >
      <div
        {...(props?.sectionId && { id: getContainerFinalSectionId(props.sectionId) })}
        className={`position-relative container-md align_${props.align}`}
        style={innerStyles}
      >
        {React.Children.map(children, (child) => React.cloneElement(child, { style: innerStyles }))}
      </div>
    </ActionsController>
  );
};

export default ContainerComponent;

const ContainerComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
};
ContainerComponent.craft = {
  name: CRAFT_ELEMENTS.CONTAINER,
  props: ContainerComponentDefaultProps,
  rules: {
    canMoveIn: (selectedNode: any, currentNode: any) => {
      if (currentNode.data.nodes.length === 0) {
        return selectedNode[0].data.displayName === 'ColumnGrid';
      } else {
        return false;
      }
    },
  },
};

import { FormValidationReturn, FormFieldTypes } from './interfaces';
const maxLengthForGeneralPurposeField = 250;
const minLengthForGeneralPurposeField = 1;

export const isPhoneValid = (value: string): FormValidationReturn => {
  // the rest is handled inside of PhoneInput2
  const valid = value.length >= 9 && value.length <= 55;

  return {
    valid,
    errorMessage: 'Die Nummer ist nicht valide.',
  };
};

export const isNameValid = (value: string): FormValidationReturn => {
  const regex = /^[-a-zA-Z \u00C0-\u00FF]+$/i; // For first and lastname: allows only Latin-1 (ISO 8859-1) characters and letters of the Latin alphabet
  const valid = regex.test(value) && value.length > 2 && value.length < 50;

  return {
    valid,
    errorMessage:
      'Es dürfen min. 2 und max. 50 Zeichen sein und nur Groß- oder Kleinbuchstaben verwendet werden.',
  };
};

export const isEmailValid = (value: string): FormValidationReturn => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = regex.test(value);

  return {
    valid,
    errorMessage: 'Die E-Mail-Adresse ist nicht valide',
  };
};

export const isCheckboxValid = (value: string): FormValidationReturn => {
  return {
    valid: !!value,
    errorMessage: '',
  };
};

export const isGeneralPurposeFieldValid = (value: string): FormValidationReturn => {
  return {
    valid:
      value.length >= minLengthForGeneralPurposeField &&
      value.length <= maxLengthForGeneralPurposeField,
    errorMessage: `Der Wert muss größer als ${minLengthForGeneralPurposeField} und kleiner als ${maxLengthForGeneralPurposeField} sein.`,
  };
};

export const validationsByName: { [key: string]: Function } = {
  [FormFieldTypes.NAME]: isNameValid,
  [FormFieldTypes.FIRST_NAME]: isNameValid,
  [FormFieldTypes.LAST_NAME]: isNameValid,
  [FormFieldTypes.EMAIL]: isEmailValid,
  [FormFieldTypes.PHONE]: isPhoneValid,
  [FormFieldTypes.GENERAL_PURPOSE]: isGeneralPurposeFieldValid,
  [FormFieldTypes.DATA_PRIVACY_CHECKBOX]: isCheckboxValid,
};

export const validateFieldValue = (value: string | boolean, name: FormFieldTypes): boolean => {
  return validationsByName[name](value);
};

export const advancedTrim = (value: string): string => {
  return (value || '').replace(/\s+/gi, ' ').trim();
};

export const guessTimezone = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    return 'Europe/Berlin';
  }
};

export const isThereAnyUTMParamExist: () => boolean = () => {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);
  let isThereAnyUTMParamExist = false;
  searchParams.forEach(() => {
    isThereAnyUTMParamExist = true;
  });
  return isThereAnyUTMParamExist;
};

export const getUtmFromUrl = (utm: string): string => {
  // Get the current URL
  const currentUrl = new URL(window.location.href);

  // Create an instance of URLSearchParams
  const searchParams = new URLSearchParams(currentUrl.search);

  // Get the value of a specific parameter
  return searchParams.get(utm) ?? '';
};

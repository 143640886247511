import React from 'react';
import { Alert } from 'react-bootstrap';

const FunnelDisabled = (): React.ReactElement => {
  return (
    <Alert variant="warning">
      <Alert.Heading>Dieser Funnel ist deaktiviert</Alert.Heading>
      <p>
        Wenn dies dein Funnel ist, kannst du ein Upgrade durchführen, um ihn wieder freizuschalten.
      </p>
    </Alert>
  );
};

export default FunnelDisabled;

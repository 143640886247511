import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../interfaces/store';
import { INITIAL_STATE } from '../config/mainData';
import {
  Funnel,
  FunnelColorTheme,
  funnelPresetElements,
  MainData,
  SubscriptionInfo,
} from '../interfaces/mainData';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../root/hooks';
import {
  buttonPresetsInitialValues,
  choicePresetsInitialValues,
  imageCarousalPresetsInitialValues,
  imagePresetsInitialValues,
} from '../helper/presetsInitialValues';

export const mainDataSlice = createSlice({
  name: 'mainData',
  initialState: INITIAL_STATE,
  reducers: {
    setInitialMainData: (state, action: PayloadAction<MainData>) => {
      state.funnel = action.payload.funnel;
      state.funnelColorTheme = action.payload.funnelColorTheme;
      state.funnelPages = action.payload.funnelPages;
      state.bookingSettings = action.payload.bookingSettings;
      state.subscriptionInfo = action.payload.subscriptionInfo;
      state.thirdPartyTrackingSettings = action.payload.thirdPartyTrackingSettings;
      state.globalSettingsFlags = action.payload.globalSettingsFlags;
      state.factorsAiEnabled = action.payload.factorsAiEnabled;
      state.bookingId = null;
      state.funnelPresets = action.payload.funnelPresets;
    },
    setBookingId: (state, action: PayloadAction<null | number>) => {
      state.bookingId = action.payload;
    },
  },
});

export const { setInitialMainData, setBookingId } = mainDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMainData = (state: RootState): MainData => state.mainData;
export const selectFunnel = (state: RootState): Funnel | {} => state.mainData.funnel;
export const selectFunnelColorTheme = (state: RootState): FunnelColorTheme | {} =>
  state.mainData.funnelColorTheme;
export const useFunnelColorTheme = (): FunnelColorTheme | {} =>
  useAppSelector((state: RootState) => state.mainData.funnelColorTheme);
export const selectBookingId = (): number | null =>
  useSelector((state: RootState) => state.mainData.bookingId);

export const useSubscriptionInfo = (): SubscriptionInfo | {} =>
  useSelector((state: RootState) => state.mainData.subscriptionInfo);

export const usePresets = (elementKey: funnelPresetElements): SubscriptionInfo | {} =>
  useSelector((state: RootState) => {
    const initialPresets = {
      [funnelPresetElements.BUTTON]: buttonPresetsInitialValues,
      [funnelPresetElements.IMAGE]: imagePresetsInitialValues,
      [funnelPresetElements.CAROUSEL]: imageCarousalPresetsInitialValues,
      [funnelPresetElements.CHOICE]: choicePresetsInitialValues,
    };

    const initialCurrentPresets = initialPresets[elementKey];
    const preset = state.mainData.funnelPresets?.find(
      (pre: any) => pre.presetType === elementKey,
    )?.preset;

    return { ...initialCurrentPresets, ...preset };
  });

export default mainDataSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../interfaces/store';
import { INITIAL_STATE } from '../config/ui';
import { LinkTreeLinkInterface } from '../../Linktree/interfaces';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: INITIAL_STATE,
  reducers: {
    setLinkTreeOpenState: (state, action: PayloadAction<boolean>) => {
      state.linkTree.open = action.payload;
    },
    setLinkTreeLinks: (state, action: PayloadAction<LinkTreeLinkInterface[]>) => {
      state.linkTree.links = action.payload;
    },
    setIsCookieBannerOpen: (state, action: PayloadAction<null | boolean>) => {
      state.cookieBanner.open = action.payload;
    },
  },
});

export const { setLinkTreeOpenState, setLinkTreeLinks, setIsCookieBannerOpen } = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLinkTreeOpenState = (state: RootState): boolean => state.ui.linkTree.open;
export const selectLinkTreeLinks = (state: RootState): LinkTreeLinkInterface[] =>
  state.ui.linkTree.links;
export const selectIsCookieBannerOpen = (state: RootState): null | boolean =>
  state.ui.cookieBanner.open;

export default uiSlice.reducer;

import { useSocket } from 'socket.io-react-hook';
import { getAuthDataFromLocalStorage } from '../Authorisation/helper/token';
import { getEnvironment } from './environment';

/* eslint-disable-next-line */
export const useAuthenticatedSocket = () => {
  const { token } = getAuthDataFromLocalStorage();
  const domain = getEnvironment().defaultDomain;

  return useSocket(domain, {
    enabled: !!token,
    path: '/tracking-socket',
    forceNew: false,
    transports: ['websocket', 'polling'],
    query: {
      EIO: 3,
      token: token ?? '',
    },
  });
};

import React, { useEffect, useState } from 'react';
import './App.scss';
import { ApolloProvider } from '@apollo/client';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import ErrorBoundary from './SharedUI/components/ErrorBoundary';
import { isLocalStorageAvailable } from './helper/localstorage';
import { StandardSpinner } from './SharedUI/components/StandardSpinner';
import FunnelNotAvailable from './FunnelNotAvailable/container/FunnelNotAvailable';
import CookieErrorPopover from './CookieErrorPopover/container/CookieErrorPopover';
import AccountNeedsToPayInfo from './AccountNeedsToPayInfo/container/AccountNeedsToPayInfo';
import Pager from './Pager/container/Pager';
import Authorisation from './Authorisation/container/Authorisation';
import { AuthorisationData } from './Authorisation/utils/interfaces';
import { getApolloClient } from './Authorisation/helper/getApolloClient';
import { setAuthDataToLocalStorage } from './Authorisation/helper/token';
import FunnelRateLimitReached from './FunnelRateLimitReached/container/FunnelRateLimitReached';
import { getUtmFromUrl } from './CraftJs/components/ContactForm/helper';

dayjs.locale('de');

const App = (): React.ReactElement => {
  const [{ token, trackingId, subscriptionStatus }, setAuthData] = useState<AuthorisationData>(
    {} as AuthorisationData,
  );
  const [error, setAuthError] = useState<Error>();
  const loading = !token && !error;
  const forceEnabled = getUtmFromUrl('force-funnel-enabled');
  const accountNeedsToPay =
    ['NEEDS_TO_PAY', 'FREEZE_PLAN'].includes(subscriptionStatus ?? '') && !forceEnabled;
  const client = getApolloClient(token);

  useEffect(() => {
    localStorage.removeItem('meetovoStepHistory');
  }, []);

  const handleSetAuthData = (authData: AuthorisationData): void => {
    setAuthData(authData);
    setAuthDataToLocalStorage(authData);
  };

  if (!isLocalStorageAvailable()) {
    return <CookieErrorPopover />;
  }

  // @ts-expect-error
  const rateLimitReached = (error?.graphQLErrors?.[0]?.message || '').includes(
    'Es sind nur maximal',
  );

  return (
    <ErrorBoundary>
      {/* this component need to stay outside off app content because they are overlays */}
      {accountNeedsToPay && <AccountNeedsToPayInfo />}

      <div className="app">
        <ApolloProvider client={client}>
          {!token && <Authorisation onSuccess={handleSetAuthData} onError={setAuthError} />}
          {token && <Pager trackingId={trackingId} token={token} />}
          {loading && <StandardSpinner />}
          {rateLimitReached && <FunnelRateLimitReached />}
          {error && !rateLimitReached && <FunnelNotAvailable />}
        </ApolloProvider>
      </div>
    </ErrorBoundary>
  );
};

export default App;

import { getUrlParam } from './url';

const alterSource = (): string => {
  const href = window.location.href;
  const source = document.referrer || '';

  if (getUrlParam('tracking-deaktiviert')) {
    return 'localhost'; // by doing so, backend will not start tracking
  }

  if (href.includes('localhost') || href.includes('127.0.0.1')) return 'lokale-umgebung';

  return source;
};

export default alterSource;

import React, { useEffect } from 'react';
import { PopoverProps } from '../interfaces/Popover';
import Icon from './IconProvider';
import PopoverPortalWrapper from './PopoverPortal';

function Popover({
  children,
  trigger,
  open,
  className,
  onClose,
}: PopoverProps): React.ReactElement | null {
  useEffect(() => {
    const appDiv = document.querySelector('.app');
    if (open) appDiv?.classList.add('popover-open');
    else appDiv?.classList.remove('popover-open');
  }, [open]);

  if (open) {
    return (
      <PopoverPortalWrapper>
        <div className={'popover-full ' + className}>
          <i className="deeper-on-hover popover-full__close" onClick={onClose}>
            <Icon name="IoIosClose" />
          </i>
          <div className="popover-full__content">{children}</div>
        </div>
      </PopoverPortalWrapper>
    );
  }

  if (!trigger) return null;

  return (
    <div className="popover-full__trigger_wrapper">
      <span className={'popover-full__trigger ' + className} onClick={onClose}>
        {trigger}
      </span>
    </div>
  );
}

export default Popover;

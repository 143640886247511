export const getUniqueIdentifier = (): string => {
  const { pathname } = window.location;
  const uniqueIdentifier = pathname;
  return uniqueIdentifier.includes('/funnel/')
    ? uniqueIdentifier.replace('/funnel/', '')
    : uniqueIdentifier.replace('/', '');
};

export const getUrlParam = (paramToGet: string, prefix?: string): any => {
  try {
    const vars: { [key: string]: string } = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      // @ts-expect-error
      (m: string, key: string, value: string) => {
        if (prefix && !key.toUpperCase().includes(prefix.toUpperCase() + '_')) return;

        if (typeof decodeURI !== 'undefined') vars[key] = decodeURI(value);
        else vars[key] = value;
      },
    );

    if (paramToGet === 'ALL') return vars;

    return paramToGet ? vars[paramToGet] || vars[paramToGet.toLowerCase()] : '';
  } catch (error) {
    if (paramToGet === 'ALL') return {};

    return '';
  }
};

import React from 'react';
import { OldChoiceForSubmission, UploadedFilesInfo } from '../../../redux/interfaces/userInput';

export interface GetIpAndUserAgentCallbackInterface {
  clientIp: string;
  clientUserAgent: string;
}

export interface FormValidationReturn {
  valid: boolean | undefined;
  errorMessage: string;
}

export interface ContactFormProps {
  phoneEnabled?: boolean;
  buttonText: string;
  displayName: string;
  legalInformationURL?: string;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  trackingId: number;
  generalPurposeFieldEnabled?: boolean;
  generalPurposeFieldPlaceholder?: string;
  generalPurposeFieldIcon?: string;
  breakNameFieldIntoFNAndLN?: boolean;
  children?: React.ReactNode;
}

export enum FormFieldTypes {
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  GENERAL_PURPOSE = 'generalPurpose',
  DATA_PRIVACY_CHECKBOX = 'dataPrivacyCheckbox',
}

export interface FormValues {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  generalPurpose: string;
  dataPrivacyCheckbox: boolean;
}

export interface FormValidation {
  name: FormValidationReturn;
  firstName: FormValidationReturn;
  lastName: FormValidationReturn;
  email: FormValidationReturn;
  phone: FormValidationReturn;
  generalPurpose: FormValidationReturn;
  dataPrivacyCheckbox: FormValidationReturn;
}

export interface ApplicationSubmitData {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  trackingId?: number;
  phone?: string;
  eventStart?: string;
  eventEnd?: string;
  choices: OldChoiceForSubmission[];
  utmParameter?: string;
  timezone?: string;
  generalPurpose?: string;
  uploadedFilesInfo: UploadedFilesInfo[];
}

import React from 'react';
import { Col } from 'react-bootstrap';
import { CRAFT_ELEMENTS } from '../config/craftElements';

interface Props {
  columnWidths: {
    sm: number;
    md: number;
    lg: number;
  };
  children?: any;
  displaySpecialIcon?: boolean;
}

const Column = ({
  children,
  columnWidths,
  displaySpecialIcon,
  ...props
}: Props): React.ReactElement => {
  let SpecialIconCom;

  const restChildren = children?.filter((single: { props: { isColumnIcon: any } }) => {
    if (single?.props.isColumnIcon) {
      SpecialIconCom = single;
      return false;
    } else {
      return true;
    }
  });

  return (
    <Col
      className="column-parent-wrapper column-wrapper"
      data-node-info="Column"
      {...columnWidths}
      {...props}
    >
      <div className="column-wrapper__inner">
        {displaySpecialIcon ? <div className="special-icon">{SpecialIconCom}</div> : null}
        <div className={`column-content ${displaySpecialIcon ? 'ms-1' : ''}`}>{restChildren}</div>
      </div>
    </Col>
  );
};

export const ColumnComponentProps = {
  columnWidths: {
    sm: 12,
    md: 6,
    lg: 3,
  },
};

Column.craft = {
  name: CRAFT_ELEMENTS.COLUMN,
  props: ColumnComponentProps,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return (
        selectedNode[0].data.displayName !== 'Column' &&
        selectedNode[0].data.displayName !== 'ColumnGrid' &&
        selectedNode[0].data.displayName !== 'ContainerComponent' &&
        selectedNode[0].data.displayName !== 'DividerComponent'
      );
    },
  },
};

export default Column;

import {
  GetOldLeadQualifierIdAndChoiceByCraftJsIdProps,
  GetOldLeadQualifierIdAndChoiceByCraftJsIdReturn,
  SetCustomStringOrNumberValuesProps,
} from '../interfaces/oldLeadQualifierMutation';
import { OldChoiceForSubmission } from '../interfaces/userInput';

export function getOldLeadQualifierIdAndChoiceByCraftJsId({
  leadQualifierCraftJsNodeId,
  choiceCraftJsNodeId,
  type,
  oldLeadQualifier,
  currentPageId,
}: GetOldLeadQualifierIdAndChoiceByCraftJsIdProps): GetOldLeadQualifierIdAndChoiceByCraftJsIdReturn {
  let thisOldLeadQualifier;
  if (leadQualifierCraftJsNodeId) {
    thisOldLeadQualifier = oldLeadQualifier.find(
      ({ craftJsNodeId, pageId }) =>
        craftJsNodeId === leadQualifierCraftJsNodeId && pageId === currentPageId,
    );
  } else {
    thisOldLeadQualifier = oldLeadQualifier.find(
      ({ choices, pageId }) =>
        choices.some(({ craftJsNodeId }) => choiceCraftJsNodeId === craftJsNodeId) &&
        pageId === currentPageId,
    );
  }

  if (!thisOldLeadQualifier)
    throw new Error('getOldLeadQualifierIdAndChoiceByCraftJsId: LeadQualifier not found');

  const thisChoice = thisOldLeadQualifier.choices.find(
    ({ craftJsNodeId }) => choiceCraftJsNodeId === craftJsNodeId,
  );

  switch (type) {
    case 'radio':
    case 'multiple':
      if (!thisChoice)
        throw new Error('getOldLeadQualifierIdAndChoiceByCraftJsId: Choice not found');

      return {
        leadQualifierId: thisOldLeadQualifier.id,
        choiceId: thisChoice.id,
        leadQualifierCraftJsNodeId: thisOldLeadQualifier.craftJsNodeId,
        choiceCraftJsNodeId: thisChoice.craftJsNodeId,
      };
    case 'range':
    case 'text':
      return {
        leadQualifierId: thisOldLeadQualifier.id,
        choiceId: thisOldLeadQualifier.choices[0].id,
        leadQualifierCraftJsNodeId: thisOldLeadQualifier.craftJsNodeId,
        choiceCraftJsNodeId: thisOldLeadQualifier.choices[0].craftJsNodeId,
      };
  }

  throw new Error('getOldLeadQualifierIdAndChoiceByCraftJsId: LeadQualifier type not existing');
}

function setCustomStringOrNumberValues({
  value,
  mutatedUserInput,
  existingUserInput,
  leadQualifierId,
  choiceId,
}: SetCustomStringOrNumberValuesProps): OldChoiceForSubmission[] {
  mutatedUserInput = existingUserInput.filter(
    (c: OldChoiceForSubmission) => c.leadQualifierId !== leadQualifierId,
  );
  mutatedUserInput = [
    ...mutatedUserInput,
    {
      leadQualifierId,
      choiceId,
    },
  ];

  if (typeof value === 'string' && value.length)
    mutatedUserInput[mutatedUserInput.length - 1].stringValue = value;

  if (typeof value === 'number') mutatedUserInput[mutatedUserInput.length - 1].numberValue = value;

  return mutatedUserInput;
}

export function mutateUserInput(
  newUserInput: OldChoiceForSubmission,
  existingUserInput: OldChoiceForSubmission[],
): OldChoiceForSubmission[] {
  const { type, choiceId, value, leadQualifierId } = newUserInput;
  const valueIsSelected = existingUserInput.some(
    (sc: OldChoiceForSubmission) => sc.choiceId === choiceId,
  );

  let mutatedUserInput: OldChoiceForSubmission[] = [];

  switch (type) {
    case 'radio':
    case 'range':
    case 'text':
      if (type === 'text' && typeof value === 'string' && !value.length)
        // kick out of from array if the value is empty
        mutatedUserInput = existingUserInput.filter(
          (sc: OldChoiceForSubmission) => sc.choiceId !== choiceId,
        );
      else
        mutatedUserInput = setCustomStringOrNumberValues({
          value,
          mutatedUserInput,
          existingUserInput,
          leadQualifierId,
          choiceId,
        });

      break;
    case 'multiple':
      if (valueIsSelected)
        mutatedUserInput = existingUserInput.filter(
          (sc: OldChoiceForSubmission) =>
            sc.choiceId !== choiceId || sc.leadQualifierId !== leadQualifierId,
        );
      else
        mutatedUserInput = [
          ...existingUserInput,
          {
            leadQualifierId,
            choiceId,
          },
        ];
      break;

    default:
      break;
  }

  return mutatedUserInput;
}

import React, { Suspense, useEffect, useState } from 'react';
import { setCurrentOldLeadQualifierIdToLocalStorage } from '../../Pager/helper';
import lz from 'lzutf8';
import { StandardSpinner } from '../../SharedUI/components/StandardSpinner';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import BodyComponent from '../components/BodyComponent';
import ContainerComponent from '../components/ContainerComponent';
import MainContainerComponent from '../components/MainContainer';
import ColumnGrid from '../components/ColumnGrid';
import Column from '../components/Column';
import RichTextComponent from '../components/RichTextComponent';
import LogoComponent from '../components/LogoComponent';
import FooterComponent from '../components/FooterComponent';
import { replacePlaceHoldersInCraftState } from '../helper';

const CraftJsUserComponents = {
  [CRAFT_ELEMENTS.BODY]: BodyComponent,
  [CRAFT_ELEMENTS.CONTAINER]: ContainerComponent,
  [CRAFT_ELEMENTS.MAIN_CONTAINER]: MainContainerComponent,
  [CRAFT_ELEMENTS.COLUMN_GRID]: ColumnGrid,
  [CRAFT_ELEMENTS.COLUMN]: Column,
  [CRAFT_ELEMENTS.RICH_TEXT]: RichTextComponent,
  [CRAFT_ELEMENTS.LOGO]: LogoComponent,
  [CRAFT_ELEMENTS.FOOTER]: FooterComponent,
  [CRAFT_ELEMENTS.BUTTON]: React.lazy(async () => await import('../components/ButtonComponent')),
  [CRAFT_ELEMENTS.IMAGE]: React.lazy(async () => await import('../components/ImageComponent')),
  [CRAFT_ELEMENTS.ICON]: React.lazy(async () => await import('../components/IconComponent')),
  [CRAFT_ELEMENTS.VIDEO]: React.lazy(async () => await import('../components/VideoComponent')),
  [CRAFT_ELEMENTS.DIVIDER]: React.lazy(async () => await import('../components/DividerComponent')),
  [CRAFT_ELEMENTS.CONTACT_FORM]: React.lazy(
    async () => await import('../components/ContactForm/container/ContactForm'),
  ),
  [CRAFT_ELEMENTS.ELEMENT_APPENDER]: React.lazy(async () => await import('./ElementAppender')),
  [CRAFT_ELEMENTS.CHOICE]: React.lazy(
    async () => await import('../components/SingleChoiceComponent/components/Choice'),
  ),
  [CRAFT_ELEMENTS.SINGLE_CHOICE]: React.lazy(
    async () => await import('../components/SingleChoiceComponent/container/SingleChoiceComponent'),
  ),
  [CRAFT_ELEMENTS.MULTIPLE_CHOICE]: React.lazy(
    async () =>
      await import('../components/MultipleChoiceComponent/container/MultipleChoiceComponent'),
  ),
  [CRAFT_ELEMENTS.VOICE_MESSAGE]: React.lazy(
    async () => await import('../components/VoiceMessageComponent/container/VoiceMessageComponent'),
  ),
  [CRAFT_ELEMENTS.LONG_ANSWER]: React.lazy(
    async () => await import('../components/LongAnswerComponent'),
  ),
  [CRAFT_ELEMENTS.RANGE_SLIDER]: React.lazy(
    async () => await import('../components/RangeSliderComponent'),
  ),
  [CRAFT_ELEMENTS.CALENDAR]: React.lazy(
    async () => await import('../components/Calendar/container/Calendar'),
  ),
  [CRAFT_ELEMENTS.ADD_CONTAINER]: React.lazy(
    async () => await import('../components/AddContainerComponent'),
  ),
  [CRAFT_ELEMENTS.UPLOAD_FILE]: React.lazy(
    async () => await import('../components/UploadFile/container/UploadFileComponent'),
  ),
  [CRAFT_ELEMENTS.HTML_WIDGET]: React.lazy(async () => await import('../components/HTMLWidget')),
  [CRAFT_ELEMENTS.CAROUSEL]: React.lazy(
    async () => await import('../components/Carousel/BuilderCarousel'),
  ),
  [CRAFT_ELEMENTS.CAROUSEL_CONTAINER]: React.lazy(
    async () => await import('../components/Carousel/CarouselContainer'),
  ),
  [CRAFT_ELEMENTS.CAROUSEL_SLIDE]: React.lazy(
    async () => await import('../components/Carousel/CarouselSlide'),
  ),
};

interface Props {
  compressedCraftState: string;
  trackingId: number;
}

const ResolvedComponentsByCraftState = ({
  compressedCraftState,
  trackingId,
}: Props): React.ReactElement | null => {
  const [toRender, setToRender] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    const allNodeIds: string[] = [];
    const craft = lz.decompress(lz.decodeBase64(compressedCraftState));
    const replacedCraft = replacePlaceHoldersInCraftState(craft);
    const craftState = JSON.parse(replacedCraft || craft);

    const resolvedComponents: React.ReactElement = (() => {
      const parse = (nodeId: string, parentNodeId?: string): React.ReactElement => {
        if (nodeId === null || nodeId === '') return <></>;

        if (!allNodeIds.includes(nodeId)) allNodeIds.push(nodeId);

        const childNodeNames: string[] = craftState[nodeId]?.nodes || [];
        const ReactComponent = (CraftJsUserComponents as any)[craftState[nodeId].type.resolvedName];
        const extendedProps = {
          ...craftState[nodeId].props,
          parentNodeId,
          nodeId,
          key: nodeId,
          trackingId,
        };

        if (childNodeNames.length === 0) return <ReactComponent {...extendedProps} />;

        const childNodes = childNodeNames.map((childNodeId) => {
          return parse(childNodeId, nodeId);
        });

        return <ReactComponent {...extendedProps}>{childNodes}</ReactComponent>;
      };

      return parse('ROOT');
    })();

    setCurrentOldLeadQualifierIdToLocalStorage(allNodeIds);
    setToRender(resolvedComponents);
  }, []);

  return <Suspense fallback={<StandardSpinner />}>{toRender}</Suspense>;
};

export default ResolvedComponentsByCraftState;

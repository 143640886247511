import { getUrlParam } from '../helper/url';
import { ThirdPartyTrackingProvider } from '../redux/interfaces/mainData';

export const cookieMessageVisible = (): boolean =>
  !!(!localStorage.meetovoShowCookieBanner || localStorage.meetovoShowCookieBanner === 'true');

export const setAllowedCookiesToLocalStorage = (ids: string[]): void => {
  localStorage.setItem('meetovoAllowedCookies', JSON.stringify(ids));
  window.dispatchEvent(new Event('meetovoAllowedCookies storage update'));
};

export const getAllowedCookiesFromLocalStorage = (): string[] => {
  const allowedCookies = localStorage.getItem('meetovoAllowedCookies');

  if (allowedCookies) return JSON.parse(allowedCookies);

  return [];
};

export const getAllowedCookies = (): string[] => {
  const preAcceptedCookies = getUrlParam(
    'ONLY_USE_IT_WHEN_THE_USER_HAS_ACCEPTED_THE_COOKIES__listOfAcceptedCookies',
  );
  const allowedCookiesFromLocalStorage = getAllowedCookiesFromLocalStorage();

  if (preAcceptedCookies && !allowedCookiesFromLocalStorage.length)
    setAllowedCookiesToLocalStorage(preAcceptedCookies.split(','));

  return getAllowedCookiesFromLocalStorage();
};

export const isTrackingServiceAllowed = (provider: ThirdPartyTrackingProvider): boolean => {
  return getAllowedCookiesFromLocalStorage().includes(provider);
};

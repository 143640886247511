import React, { useEffect } from 'react';
import { FunnelPage } from '../../redux/interfaces/mainData';
import { useWizard } from 'react-use-wizard';
import { setupHistoryBackForWizard } from '../helper';
import ProgressBar from '../../SharedUI/components/ProgressBar';
import {
  fireConversionsApi,
  fireFacebookPixel,
  fireGoogleTag,
  fireTikTokPixel,
  fireLinkedInEvent,
} from '../../Pager/utils/tracking';
import ResolvedComponentsByCraftState from './ResolvedComponentsByCraftState';
import { useAppSelector } from '../../redux/root/hooks';
import { selectMainData } from '../../redux/slices/mainData';
import { factorsAiTrackEvent } from '../../helper/segmetrics';

const Page = (funnelPage: FunnelPage): React.ReactElement => {
  const { factorsAiEnabled } = useAppSelector(selectMainData);

  const {
    customJs,
    facebookPixelEvent,
    googleTagTrigger,
    trackingId,
    tikTokPixelEvent,
    linkedInEvent,
    name,
  } = funnelPage;
  const { goToStep } = useWizard();

  useEffect(() => {
    window.goToStep = goToStep;
    setupHistoryBackForWizard();
  }, [goToStep]);

  useEffect(() => {
    fireFacebookPixel(facebookPixelEvent);
    fireTikTokPixel(tikTokPixelEvent, name);
    fireConversionsApi(facebookPixelEvent);
    fireGoogleTag(googleTagTrigger);
    fireLinkedInEvent(linkedInEvent);

    if (factorsAiEnabled) factorsAiTrackEvent('FUNNEL_PAGE_VIEWED');
  }, []);

  useEffect(() => {
    if (customJs) {
      const fragment = document.createRange().createContextualFragment(customJs);
      document.body.appendChild(fragment);
    }
  }, [funnelPage.craftState]);

  return (
    <>
      <ProgressBar />
      <ResolvedComponentsByCraftState
        compressedCraftState={funnelPage.craftState}
        trackingId={trackingId}
      />
    </>
  );
};

export default Page;

export function getValueFromKeyValueArrayOfObjects(
  array: Array<{ key: string; value: string }> | null,
  key: string,
): any {
  if (!array) return undefined;

  const found = array.find((obj) => obj.key === key);
  if (found) {
    const value = found.value;
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        if (/^\d+$/.test(value)) {
          return parseInt(value);
        }
      }
    }
    return value;
  }

  return undefined;
}

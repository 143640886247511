import { UIState } from '../interfaces/ui';

export const INITIAL_STATE: UIState = {
  cookieBanner: {
    open: null,
  },
  linkTree: {
    open: false,
    links: [
      {
        title: 'Impressum',
        url: 'https://',
      },
      {
        title: 'Datenschutz',
        url: 'https://',
      },
    ],
  },
};

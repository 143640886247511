import React from 'react';
import { Alert } from 'react-bootstrap';

const FunnelRateLimitReached = (): React.ReactElement => {
  return (
    <Alert variant="danger">
      <h5 className="alert-heading">Aufruflimit erreicht</h5>
      Dies ist ein Sicherheitsmechanismus, weitere Anfragen werden gesperrt. Dieser Funnel wurde
      unverhältnismäßig häufig aufgerufen. Wenn dies dein Funnel ist, wende dich bitte an den
      Support.
    </Alert>
  );
};

export default FunnelRateLimitReached;

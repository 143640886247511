import React, { useState, useEffect } from 'react';
import { isLocalStorageAvailable } from '../../helper/localstorage';
import { isEmoji } from '../../helper/emoji';
import { getEnvironment } from '../../helper/environment';

interface IconProps {
  name: string;
  onClick?: (e: any) => any;
  className?: string;
  disabled?: boolean;
  color?: string;
  style?: { [key: string]: string | number };
}

interface GetIconSource {
  iconBasepath: string;
  name: string;
  setIconSourceCode: (source: string) => any;
}

export const NotFoundIcon =
  '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M331.3 308.7L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-15.6-7.1-22.6 0-7.1 7.1-6 16.6 0 22.6l52.7 52.7-52.7 52.7c-6.7 6.7-6.4 16.3 0 22.6 6.4 6.4 16.4 6.2 22.6 0l52.7-52.7 52.7 52.7c6.2 6.2 16.4 6.2 22.6 0 6.3-6.2 6.3-16.4 0-22.6z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>';

function getIconSource({ iconBasepath, name, setIconSourceCode }: GetIconSource): void {
  const _isLocalStorageAvailable = isLocalStorageAvailable();

  const cachedIcon = _isLocalStorageAvailable ? localStorage.getItem(name) ?? '' : '';

  if (!cachedIcon)
    fetch(`${iconBasepath}${name}.svg`)
      .then(async (response) => await response.text())
      .then((data) => {
        if (data.includes('<svg')) {
          setIconSourceCode(data);
          if (_isLocalStorageAvailable) localStorage.setItem(name, data);
        } else setIconSourceCode(NotFoundIcon);
      })
      .catch(() => {
        setIconSourceCode(NotFoundIcon);
      });
  else setIconSourceCode(cachedIcon);
}

function IconProvider({
  name,
  onClick,
  className,
  disabled,
  ...props
}: IconProps): React.ReactElement {
  const { defaultDomain } = getEnvironment();
  const [iconSourceCode, setIconSourceCode] = useState('');
  const iconBasepath = `${defaultDomain}/funnel/icon-library/`;
  const _isEmoji = isEmoji(name);

  useEffect(() => {
    if (!_isEmoji) getIconSource({ iconBasepath, name, setIconSourceCode });
  });

  if (_isEmoji)
    return (
      <span className="icon-provider icon-provider__emoji" {...props}>
        {name}
      </span>
    );

  return (
    <i
      className={'icon-provider ' + name + ' ' + (className ?? '') + (disabled ? ' disabled' : '')}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: iconSourceCode }}
      {...props}
    ></i>
  );
}

export default IconProvider;

export enum CRAFT_ELEMENTS {
  BODY = 'Body',
  BUTTON = 'Button',
  IMAGE = 'Image',
  CONTAINER = 'Container',
  ICON = 'Icon',
  MAIN_CONTAINER = 'MainContainer',
  COLUMN_GRID = 'ColumnGrid',
  COLUMN = 'Column',
  VIDEO = 'Video',
  COL = 'Col',
  FONT_AWESOME_ICON = 'FontAwesomeIcon',
  RICH_TEXT = 'RichText',
  ADD_CONTAINER = 'AddContainer',
  DIVIDER = 'Divider',
  LOGO = 'Logo',
  FOOTER = 'Footer',
  CONTACT_FORM = 'ContactForm',
  ELEMENT_APPENDER = 'ElementAppender',
  CHOICE = 'Choice',
  SINGLE_CHOICE = 'SingleChoice',
  MULTIPLE_CHOICE = 'MultipleChoice',
  VOICE_MESSAGE = 'VoiceMessage',
  LONG_ANSWER = 'LongAnswer',
  RANGE_SLIDER = 'RangeSlider',
  CALENDAR = 'Calendar',
  UPLOAD_FILE = 'UploadFile',
  HTML_WIDGET = 'HTMLWidget',
  CAROUSEL = 'Carousel',
  CAROUSEL_CONTAINER = 'CarouselContainer',
  CAROUSEL_SLIDE = 'CarouselSlide',
}

import React from 'react';
import Popover from '../../SharedUI/components/Popover';
import { isInIframe, openBookingInNewTab } from '../../helper/localstorage';
import { Button } from 'react-bootstrap';
import IconProvider from '../../SharedUI/components/IconProvider';

function CookieErrorPopover(): React.ReactElement {
  const _isInIframe = isInIframe();

  return (
    <Popover className="no-cookie-popover" open>
      <div className="no-cookie-popover__inner vertical-centered">
        <IconProvider name="FaCookieBite" />
        <h4>Cookies geblockt</h4>
        <p>
          {_isInIframe
            ? 'Dein Browser erlaubt keine Third Party Cookies. Dies kann an einem Adblocker oder an deinen Einstellungen liegen. Versuche MEETOVO in einem neuen Tab zu öffnen.'
            : 'Dein Browser erlaubt keine Third Party Cookies. Dies kann an einem Adblocker oder an deinen Einstellungen liegen. Daher ist die Anfrage leider nicht möglich.'}
        </p>
        {_isInIframe ? (
          <Button onClick={openBookingInNewTab} variant="outline-light">
            In neuem Tab öffnen
          </Button>
        ) : null}
      </div>
    </Popover>
  );
}

export default CookieErrorPopover;

import { MainData } from '../interfaces/mainData';

export const INITIAL_STATE: MainData = {
  funnel: {
    title: '',
    uniqueIdentifier: '',
    brandingEnabled: false,
    cookieBanner: false,
    language: 'DE',
  },
  funnelColorTheme: {
    accentColor: '#1dace3',
    accentColorContrast: '',
    backgroundColor: '#F7F7F7',
    cardBackgroundColor: '#FFF',
    cardIconColor: '#1F296D',
    cardTextColor: '#888',
    headlineColor: '#7f2222',
    fontFamily: 'Open Sans',
    textColor: '#888',
  },
  funnelPages: [],
  bookingSettings: {},
  subscriptionInfo: {},
  thirdPartyTrackingSettings: [],
  globalSettingsFlags: [],
  factorsAiEnabled: false,
  bookingId: null,
  funnelPresets: [],
};

import React from 'react';
interface Props {
  background?: string;
  paddingTop?: string;
  paddingBottom?: string;
  marginTop?: string;
  marginBottom?: string;
  children?: React.ReactElement;
  style?: any;
  className?: string;
  canDrag?: boolean;
  parentTag?: any;
  parentTagProps?: any;
}

export const ActionsController = (props: Props): React.ReactElement => {
  const { children, parentTag, parentTagProps } = props;
  const Tag = parentTag ?? 'div';

  return (
    <Tag
      {...props}
      className={`actions-controller__wrapper ${props.className ?? ''}`}
      {...parentTagProps}
    >
      {children}
    </Tag>
  );
};

import { useMutation } from '@apollo/react-hooks';
import alterSource from '../../helper/alterSource';
import { isCustomDomain } from '../../helper/environment';
import { GET_TOKEN } from '../utils/graphql';
import { getUniqueIdentifier } from '../../helper/url';
import { AuthorisationProps } from '../utils/interfaces';
import { useEffect } from 'react';

const Authorisation = ({ onSuccess, onError }: AuthorisationProps): null => {
  const customDomain = isCustomDomain() ? window.location.host : '';
  const uniqueIdentifier = getUniqueIdentifier();
  const source = alterSource();
  const [getToken] = useMutation(GET_TOKEN);

  useEffect(() => {
    if (!uniqueIdentifier && !customDomain) {
      onError(new Error('No unique identifier or custom domain'));
      return;
    }

    getToken({
      variables: {
        input: {
          uniqueIdentifier,
          source,
          customDomain,
          slug: customDomain ? window.location.pathname : '',
          utmParameter: window.location?.search?.substring(1) || '',
        },
      },
    })
      .then((res) => {
        const { token, trackingId, subscriptionStatus } = res.data.login;

        onSuccess({ token, trackingId, subscriptionStatus });
      })
      .catch((e) => {
        console.error(e);
        onError(e);
      });
  }, []);

  return null;
};

export default Authorisation;

import ReactDOM from 'react-dom';

const PopoverPortalWrapper = ({ children }: any): React.ReactPortal | null => {
  const baseDocument =
    // @ts-expect-error
    document.querySelector('.booking-dummy')?.contentDocument || document;

  if (document.querySelector('.booking-dummy') && !baseDocument.querySelector('.app')) return null;

  let popoverPortalWrapper = baseDocument.getElementById('popover-portal-wrapper');

  if (!popoverPortalWrapper) {
    popoverPortalWrapper = baseDocument.createElement('div');
    popoverPortalWrapper.setAttribute('id', 'popover-portal-wrapper');
    const container = baseDocument.querySelector('.app') || baseDocument.body;
    container?.appendChild(popoverPortalWrapper);
  }

  if (!popoverPortalWrapper) return null;

  return ReactDOM.createPortal(children, popoverPortalWrapper);
};

export default PopoverPortalWrapper;

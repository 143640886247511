import React from 'react';
import { Row } from 'react-bootstrap';
import { CRAFT_ELEMENTS } from '../config/craftElements';

interface Props {
  children?: any;
}

const ColumnGrid = ({ children, ...props }: Props): React.ReactElement => {
  return (
    <Row {...props} className="column-grid-container">
      {children}
    </Row>
  );
};

ColumnGrid.craft = {
  name: CRAFT_ELEMENTS.COLUMN_GRID,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === 'Column';
    },
  },
};

export default ColumnGrid;

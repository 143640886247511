import React from 'react';

function AccountNeedsToPayInfo(): React.ReactElement {
  return (
    <div className="account-needs-to-pay-info block-interaction">
      <div className="account-needs-to-pay-info__inner">
        <div className="account-needs-to-pay-info__inner__content">
          <img
            loading="lazy"
            className="meetovo-logo"
            src={`${process.env.PUBLIC_URL}/meetovo-logo-weiss-600.png`}
          />
          <h3>Dieser Funnel ist zurzeit nicht verfügbar</h3>
          <p>
            Wenn dies dein Funnel ist, dann logge dich in dein Dashboard ein, um weitere
            Informationen zu erhalten. Bei Fragen wende dich gerne an{' '}
            <a href="mailto: service@meetovo.de">service@meetovo.de</a>
          </p>
        </div>
      </div>
      <div className="account-needs-to-pay-info__overlay block-interaction__overlay"></div>
    </div>
  );
}

export default AccountNeedsToPayInfo;

import React from 'react';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { animated, useSpring } from '@react-spring/web';

interface Props {
  background?: string;
  padding?: string;
  children?: any;
}

const MainContainerComponent = ({
  background,
  padding,
  children,
  ...props
}: Props): React.ReactElement => {
  const styles = { margin: '0 0', background, padding: `${0}px` };
  const springStyles = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <animated.div style={springStyles}>
      <div {...props} style={styles}>
        {children}
      </div>
    </animated.div>
  );
};

export default MainContainerComponent;

MainContainerComponent.craft = {
  name: CRAFT_ELEMENTS.MAIN_CONTAINER,
};

import { getEnvironment } from './environment';

export const injectScriptIntoHead = (scriptString: string): void => {
  try {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptString;

    document.head.appendChild(script);
  } catch (error) {
    console.error('Error injecting script into head', error);
  }
};

export const injectSegMetricsScript = (): void => {
  const { isDev } = getEnvironment();

  if (isDev) {
    console.log('Not injecting seg metrics script in dev');
    return;
  }

  const fullScript = `
  var _segq = _segq || [];
  var _segs = _segs || {};
  (function () {var dc = document.createElement('script');dc.type = 'text/javascript';dc.async = true;dc.src = '//tag.segmetrics.io/a1erwQ.js';var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(dc,s);})();
`;

  injectScriptIntoHead(fullScript);
};

export const factorsAiTrackEvent = (event: string): void => {};

import { getUniqueIdentifier } from './url';

interface EnvironmentInterface {
  isDev: boolean;
  graphqlEndpoint: string;
  domain: string;
  defaultDomain: string;
  shouldEnableHotjar: boolean;
}

const userIsFunnelOwner = (): boolean => {
  try {
    const domain = 'https://' + window.location.host;
    let funnelOwner = localStorage.meetovoFunnelOwner;
    funnelOwner = /\[.*\]/.test(funnelOwner) ? JSON.parse(funnelOwner) : funnelOwner; // handle multi-funnel
    return funnelOwner === 'all' || (funnelOwner || []).includes(getUniqueIdentifier() || domain);
  } catch (error) {
    return false;
  }
};

const shouldEnableHotjar = (): boolean => {
  try {
    return !userIsFunnelOwner() && localStorage.disableHotjar !== 'true';
  } catch (error) {
    return false;
  }
};

export const isCustomDomain = (): boolean =>
  (!window.location.host.includes('meetovo.de') &&
    !window.location.host.includes('localhost') &&
    !window.location.host.includes('.ngrok.io') &&
    !window.location.href.includes('mtignorecustomdomain')) ||
  window.location.href.includes('forcecustomdomain');

export const getEnvironment = (): EnvironmentInterface => {
  if (
    window.location.href.includes('app-test.meetovo.de') ||
    window.location.href.includes('localhost') ||
    window.location.host.includes('.ngrok.io') ||
    window.location.href.includes('mtignorecustomdomain') ||
    window.location.href.includes('forcecustomdomain')
  ) {
    return {
      isDev: true,
      graphqlEndpoint: 'https://app-test.meetovo.de/graphql',
      domain: 'https://' + window.location.host,
      defaultDomain: 'https://app-test.meetovo.de',
      shouldEnableHotjar: false,
    };
  } else {
    return {
      isDev: false,
      graphqlEndpoint: 'https://app.meetovo.de/graphql',
      domain: 'https://' + window.location.host,
      defaultDomain: 'https://app.meetovo.de',
      shouldEnableHotjar: shouldEnableHotjar(),
    };
  }
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../redux/root/hooks';
import { useWizard } from 'react-use-wizard';
import {
  setLocalStateByLeadQualifierCraftJsNodeId,
  setOldLeadQualifierToSubmit,
  useOldLeadQualifierLoading,
} from '../../redux/slices/userInput';
import { getStepHistory, setStepHistory } from '../../CraftJs/helper';
import { getLocalStorageData, setLocalStorageData } from '../helper';
import {
  FunnelPages,
  OldChoiceForSubmission,
  OldLeadQualifier,
} from '../../redux/interfaces/userInput';

const pathsToIgnore = ['https://app.meetovo.de', 'https://app-test.meetovo.de', '/dashboard/'];
const expirationTime = 72 * 60 * 60 * 1000; // 3 days
const getLSKey = (funnelId: number): string => `meetovo-funnel-${funnelId}`;

const TemporarySaveFunnelData = ({ funnelId }: { funnelId: number }): null => {
  const oldLeadQualifierLoading = useAppSelector(useOldLeadQualifierLoading);

  const { isLastStep } = useWizard();
  const localStateByCraftJsNodeId = useSelector(
    (state: any) => state.userInput.localStateByLeadQualifierCraftJsNodeId,
  );
  const oldLeadQualifierToSubmit = useSelector(
    (state: any) => state.userInput.oldLeadQualifierToSubmit,
  );

  const getLeadQualifier = useSelector((state: any) => state.userInput.oldLeadQualifier);

  const getFunnelPages = useSelector((state: any) => state.mainData.funnelPages);

  const dispatch = useAppDispatch();

  const removeDataFromState = (): void => {
    dispatch(setLocalStateByLeadQualifierCraftJsNodeId({}));
    dispatch(setOldLeadQualifierToSubmit([]));
    localStorage.removeItem(getLSKey(funnelId));
  };

  const getFilterOldLeadQualifier = (
    oldLeadQualifierToSubmit: OldChoiceForSubmission[],
  ): OldChoiceForSubmission[] => {
    const oldLeadQualifiers: OldChoiceForSubmission[] = [];
    const hashMap = getLeadQualifier.reduce((acc: any, item: OldLeadQualifier) => {
      acc[item.id] = item.pageId;
      return acc;
    }, {});

    oldLeadQualifierToSubmit.forEach((item: OldChoiceForSubmission) => {
      const leadQualifierPageId = hashMap[item.leadQualifierId];
      const isPageExist = getFunnelPages.some(
        (item: FunnelPages) => item.id === leadQualifierPageId,
      );
      if (isPageExist) {
        oldLeadQualifiers.push(item);
      }
    });
    return oldLeadQualifiers;
  };

  useEffect(() => {
    if (oldLeadQualifierLoading) return;
    const url = window.location.href;
    const referrer = document.referrer;
    const parsedLocalStorageData = getLocalStorageData(getLSKey(funnelId));
    const { timestamp, data, stepHistory, oldLeadQualifierToSubmit } = parsedLocalStorageData;
    if (!isLastStep && !pathsToIgnore.includes(url) && !pathsToIgnore.includes(referrer)) {
      const now = Date.now();
      if (now - timestamp < expirationTime) {
        if (data && Object.keys(data).length) {
          dispatch(setLocalStateByLeadQualifierCraftJsNodeId(data));
          if (oldLeadQualifierToSubmit.length) {
            const filteredOldLeadQualifierToSubmit =
              getFilterOldLeadQualifier(oldLeadQualifierToSubmit);
            dispatch(setOldLeadQualifierToSubmit(filteredOldLeadQualifierToSubmit));
          }
        }
        setStepHistory(stepHistory);
      } else {
        removeDataFromState();
      }
    }
  }, [getLeadQualifier]);

  useEffect(() => {
    if (isLastStep) {
      removeDataFromState();
    }
  }, [isLastStep]);

  useEffect(() => {
    if (oldLeadQualifierLoading) return;
    const currentTime = Date.now();
    const temporaryData = {
      timestamp: currentTime,
      data: localStateByCraftJsNodeId,
      stepHistory: getStepHistory(),
      oldLeadQualifierToSubmit,
    };
    setLocalStorageData(getLSKey(funnelId), temporaryData);
  }, [localStateByCraftJsNodeId]);

  return null;
};

export default TemporarySaveFunnelData;

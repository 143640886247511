import { UserInputState } from '../interfaces/userInput';

export default {
  oldLeadQualifier: [],
  oldLeadQualifierToSubmit: [],
  localStateByLeadQualifierCraftJsNodeId: {},
  selectedTimeSlot: {
    start: null,
    end: null,
    durationInMinutes: null,
  },
  fileIds: [],
  uploadedFilesInfo: [],
  isButtonEnabled: true,
  oldLeadQualifierLoading:true
} as UserInputState;

import React from 'react';
import { useWizard } from 'react-use-wizard';
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap';
import { useAppSelector } from '../../redux/root/hooks';
import { selectMainData } from '../../redux/slices/mainData';

const ProgressBar = (): React.ReactElement => {
  const { activeStep } = useWizard();
  const { funnelPages } = useAppSelector(selectMainData);
  const progressInPercent = ((activeStep + 1) / funnelPages.length) * 100;

  return <BootstrapProgressBar className="bottom-progress-bar" now={progressInPercent} />;
};

export default ProgressBar;

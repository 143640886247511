import React from 'react';
import Popover from '../../SharedUI/components/Popover';
import { useAppDispatch, useAppSelector } from '../../redux/root/hooks';
import {
  selectLinkTreeLinks,
  selectLinkTreeOpenState,
  setLinkTreeOpenState,
} from '../../redux/slices/ui';
import { LinkTreeLinkInterface } from '../interfaces';

function Linktree(): React.ReactElement {
  const open = useAppSelector(selectLinkTreeOpenState);
  const links = useAppSelector(selectLinkTreeLinks);
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(setLinkTreeOpenState(false));
  };

  return (
    <Popover className="legal-information" onClose={handleClose} open={open}>
      <ul className="linktree">
        {links.map(
          ({ title, url }: LinkTreeLinkInterface, i: number): React.ReactElement => (
            <li className="linktree__link" key={i}>
              <a href={url} target="_blank" rel="noreferrer">
                {title}
              </a>
            </li>
          ),
        )}
      </ul>
    </Popover>
  );
}

export default Linktree;

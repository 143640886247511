import { GetCookieBannerTextProps, GetCookieBannerTextReturn } from './interfaces';

export const videosEnabledId = 'videos';
export const getCookieBannerText = ({
  language,
  facebookPixelEnabled,
  facebookConversionsApiEnabled,
  googleTagManagerEnabled,
  handleCheckedToggles,
  videosEnabled,
  tiktokPixelEnabled,
  linkedInEventsEnabled,
}: GetCookieBannerTextProps): GetCookieBannerTextReturn => {
  let legalInformationText: string = '"Rechtliche Informationen"';
  let acceptButtonText: string = 'Alle Datenschutzoptionen akzeptieren';
  let cookieMessageText1: string =
    'Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu deiner Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die du ihnen bereitgestellt hast oder die du im Rahmen deiner Nutzung der Dienste gesammelt hast. Am Ende jeder Seite kannst du alle Einstellungen über den Link "Datenschutzeinstellungen" wieder ändern und Einwilligungen widerrufen. Weitere Informationen fndest du unter ';
  let cookieMessageText2: string =
    'Du kannst unter "Datenschutzeinstellungen" anpassen welche Cookies genutzt werden bzw. Cookies ablehnen.';
  let openCookieSettingsText: string = 'Datenschutzeinstellungen';
  let rejectAllButtonText = 'Alle Datenschutzoptionen ablehnen';

  let consentManager: any = {
    headline: 'Datenschutzeinstellungen',
    explanation: `
    <b>Erklärung von Cookies</b>
    <p>Cookies sind ein zentraler Bestandteil der Technologie in deinem Browser. Sie ermöglichen es, dass Websites eine bessere Interneterfahrung bieten können. Wenn du beispielsweise einen Termin buchst, kann unsere Website dich beim nächsten Mal wiedererkennen und du musst deine Daten nicht erneut eingeben.</p>

    <b>Cookie-Nutzung</b>
    <p>Wir nutzen Cookies ausschließlich, um die Funktionsweise unserer Websites zu verbessern und unsere Kampagnen zu optimieren. Du kannst diese dennoch blockieren. Wenn unten die Einstellungen für die Verarbeitung von Facebook, Google, LinkedIn, TikTok oder Video aufgeführt sind und du diesen zustimmst, werden deine Daten auch außerhalb der EU verarbeitet (Art. 49 DSGVO).</p>

    <b>Anpassung</b>
    <p>Du kannst einzelne oder alle Cookies und Tracking-Mechanismen blockieren. Dies kann jedoch dazu führen, dass bestimmte Funktionen nicht mehr nutzbar sind. Auf vielen Websites müssen beispielsweise Cookies aktiviert sein, damit du dich anmelden kannst. Uns helfen sie auch dabei, die Website zu optimieren und dir einen besseren Service bieten zu können.</p>
    
    <p>Am Ende der Seite kannst du die Datenschutzeinstellungen bearbeiten. Weitere Informationen zum Anpassen deiner Datenschutzeinstellungen findest du auch in der Hilfefunktion deines Browsers.</p>
    
    <b>Diese Meldung nicht mehr anzeigen</b>
    <p>Wenn du Cookies regelmäßig löscht, werden diese Hinweise zum Datenschutz weiterhin eingeblendet, da wir nicht wissen können, ob du diese bereits gesehen hast.</p>`,

    acceptAll: 'Alle Datenschutzoptionen akzeptieren',
    acceptSelection: 'Einstellungen übernehmen',
    toggles: [
      {
        id: 'meetovo-tracking',
        title: 'Eingabe-Tracking bearbeiten',
        explanation:
          'Sichert die Eingaben, die auf dieser Website eingegeben werden. Wir analysieren damit das Eingabeverhalten unserer Besucher.',
        callback: handleCheckedToggles,
      },
    ],
  };

  let confirmPopup = {
    text: `Durch deine derzeitigen Einstellungen können wir und die Plattformen, mit denen wir
    arbeiten, keine personalisierten Inhalte für dich anzeigen bzw. die Website
    analysieren. Es kann außerdem zu Einschränkungen bei der Benutzung kommen. Willst du
    deine Datenschutzeinstellungen trotzdem speichern?`,
    acceptSelection: 'Einstellungen übernehmen',
    acceptAll: 'Alle Datenschutzoptionen akzeptieren',
  };

  if (language === 'EN') {
    confirmPopup = {
      text: `Your current settings prevent us and the platforms we work with from displaying personalized content for you or analyzing the website. You may also experience some limitations in your use of the site. Do you still want to save your data privacy settings?`,
      acceptSelection: 'Apply settings',
      acceptAll: 'Accept all data privacy options',
    };

    legalInformationText = 'Legal information';
    acceptButtonText = 'Accept all data privacy options';
    cookieMessageText1 =
      'We use cookies to personalize content and ads, provide social media features, and analyze traffic to our website. We also share information about your use of our website with our social media, advertising and analytics partners. Our partners may combine this information with other data that you have provided to them or that you have collected as part of your use of the Services. At the bottom of each page, you can change all settings and revoke consents via the "Open data privacy settings". You can find more information at ';
    cookieMessageText2 =
      'You can customize which cookies are used or reject cookies under "Open data privacy settings".';
    openCookieSettingsText = 'Open data privacy settings';
    rejectAllButtonText = 'Reject all data privacy options';

    consentManager = {
      headline: 'Information for cookies',
      explanation: `<b>Information about cookies</b>
        <p>Cookies are a central part of the technology in your browser. They allow websites to provide a better internet experience. For example, when you book an appointment, our website can recognize you the next time you visit and you do not have to re-enter your information.</p>
    
        <b>Cookie Settings</b>
        <p>You can block individual cookies or all data privacy options. However, this may mean that certain functions are no longer usable. For example, many websites require cookies to be enabled in order for you to log in. They also help us to optimize the website and provide you with a better service.</p>
        
        <p>You can edit your data privacy settings at the bottom of the page. You can also find more information about customizing your data privacy settings in your browser's help feature.</p>
        
        <b>Cookie Usage</b>
        <p>We only use cookies to improve the way our websites work and to optimize our campaigns. You can still block them. If the settings for Facebook, Google, LinkedIn, TikTok or Video processing are listed below and you agree to them, your data will also be processed outside the EU (Art. 49 DSGVO).</p>
        
        <b>Do not show this message again</b>
        <p>If you delete cookies regularly, these privacy notices will continue to be displayed, as we have no way of knowing whether you have already seen them.</p>`,
      acceptAll: 'Accept all data privacy options',
      acceptSelection: 'Apply settings',
      toggles: [
        {
          id: 'meetovo-tracking',
          title: 'Edit input tracking',
          explanation:
            'Records all information entered on this website. We use it to analyse the input behaviour of our visitors.',
          callback: handleCheckedToggles,
        },
      ],
    };
  }

  if (facebookConversionsApiEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: 'facebook-conversion-api',
      title: 'Facebook Conversions API bearbeiten',
      explanation:
        'Die Facebook Conversions API zeichnet über den Server auf, welche Seiten besucht wurden. Wir nutzen diese, um unsere Facebook-Kampagnen zu optimieren.',
      callback: handleCheckedToggles,
    });

  if (facebookConversionsApiEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: 'facebook-conversion-api',
      title: 'Edit Facebook Conversions API',
      explanation:
        'The Facebook Conversions API records via the server which pages were visited. We use this to optimize our Facebook campaigns.',
      callback: handleCheckedToggles,
    });

  if (facebookPixelEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: 'facebook-pixel',
      title: 'Facebook-Pixel bearbeiten',
      explanation:
        'Facebook-Pixel zeichnen auf welche Seiten besucht wurden. Wir nutzen Facebook-Pixel, um unsere Facebook-Kampagnen zu optimieren.',
      callback: handleCheckedToggles,
    });

  if (facebookPixelEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: 'facebook-pixel',
      title: 'Edit Facebook-Pixel',
      explanation:
        'Facebook-Pixel records which pages have been visited. We use Facebook-Pixel to optimize our Facebook campaigns.',
      callback: handleCheckedToggles,
    });

  if (tiktokPixelEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: 'tiktok-pixel',
      title: 'Edit TikTok-Pixel',
      explanation:
        'TikTok-Pixel records which pages have been visited. We use TikTok-Pixel to optimize our TikTok campaigns.',
      callback: handleCheckedToggles,
    });

  if (tiktokPixelEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: 'tiktok-pixel',
      title: 'TikTok-Pixel bearbeiten',
      explanation:
        'TikTok-Pixel zeichnet auf, welche Seiten besucht wurden. Wir verwenden TikTok-Pixel, um unsere TikTok-Kampagnen zu optimieren.',
      callback: handleCheckedToggles,
    });

  if (linkedInEventsEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: 'linkedin-event',
      title: 'Edit Linkedin Insight-Tag',
      explanation:
        'Linkedin Insight-Tag records which pages have been visited. We use LinkedIn Insight-Tag to optimize our LinkedIn campaigns.',
      callback: handleCheckedToggles,
    });

  if (linkedInEventsEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: 'linkedin-event',
      title: 'Linkedin Insight-Tag bearbeiten',
      explanation:
        'Linkedin Insight-Tag zeichnet auf, welche Seiten besucht wurden. Wir verwenden LinkedIn Insight-Tag, um unsere LinkedIn-Kampagnen zu optimieren.',
      callback: handleCheckedToggles,
    });

  if (googleTagManagerEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: 'google-tag-manager',
      title: 'Google Tag Manager bearbeiten',
      explanation:
        'Der Google Tag Manager zeichnet auf welche Seiten besucht wurden. Wir nutzen Google Tag Manager, um unsere Google-Kampagnen zu optimieren.',
      callback: handleCheckedToggles,
    });

  if (googleTagManagerEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: 'google-tag-manager',
      title: 'Edit Google Tag Manager',
      explanation:
        'The Google Tag Manager records which pages have been visited. We use Google Tag Manager to optimize our Google campaigns.',
      callback: handleCheckedToggles,
    });

  if (videosEnabled && language !== 'EN')
    consentManager.toggles.unshift({
      id: videosEnabledId,
      title: 'Video bearbeiten',
      explanation:
        'Wir nutzen Videos um die Inhalte auf dieser Seite ansprechend zu vermitteln. Beim Anschauen von Videos können Analysedaten z.B. über die Abspieldauer an Drittanbieter übertragen werden.',
      callback: handleCheckedToggles,
    });

  if (videosEnabled && language === 'EN')
    consentManager.toggles.unshift({
      id: videosEnabledId,
      title: 'Edit video',
      explanation:
        'We use videos to show the content on this page in an appealing way. When watching videos, analytics data may be transmitted to third parties, e.g. about the playback time.',
      callback: handleCheckedToggles,
    });

  if (language !== 'EN')
    consentManager.toggles.unshift({
      id: 'google-fonts',
      title: 'Google Fonts bearbeiten',
      explanation:
        'Google Fonts ist eine Sammlung von Schriftarten, die wir nutzen, um diese Seite in einem ansprechenden Design darzustellen.',
      callback: handleCheckedToggles,
    });

  if (language === 'EN')
    consentManager.toggles.unshift({
      id: 'google-fonts',
      title: 'Edit Google Fonts',
      explanation:
        'Google Fonts is a collection of fonts that we use to present this site in a beautiful design.',
      callback: handleCheckedToggles,
    });

  return {
    banner: {
      legalInformationText,
      acceptButtonText,
      cookieMessageText1,
      cookieMessageText2,
      openCookieSettingsText,
      rejectAllButtonText,
    },
    consentManager,
    confirmPopup,
  };
};

import React from 'react';
import { CRAFT_ELEMENTS } from '../config/craftElements';

function BodyComponent({ children, ...props }: any): React.ReactElement {
  return <div {...props}>{children}</div>;
}

BodyComponent.craft = {
  name: CRAFT_ELEMENTS.BODY,
  rules: {
    canMoveIn: () => {
      return false;
    },
  },
};

export default BodyComponent;

import React from 'react';
import { Alert } from 'react-bootstrap';

const FunnelNotAvailable = (): React.ReactElement => {
  return (
    <Alert variant="danger">
      <h5 className="alert-heading">Funnel nicht verfügbar</h5>
      Die URL dieses Funnels hat sich möglicherweise geändert oder enthält einen Tippfehler.
    </Alert>
  );
};

export default FunnelNotAvailable;

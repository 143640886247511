import gql from 'graphql-tag';

export const GET_TOKEN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      token
      trackingId
      subscriptionStatus
    }
  }
`;

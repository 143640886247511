import React from 'react';
import { Spinner } from 'react-bootstrap';

interface SpinnerProps {
  animation?: string;
  role?: string;
  variant?: string;
}

const defaultProps: SpinnerProps = {
  animation: 'border',
  role: 'status',
  variant: 'primary',
};

export const StandardSpinner = (props: SpinnerProps = {}): React.ReactElement => {
  const spinnerProps: any = { ...defaultProps, ...props };

  return <Spinner className="loader-center" {...spinnerProps}></Spinner>;
};

import React, { useEffect } from 'react';
import { FUNNEL_THEME_KEYS, Funnel, SubscriptionInfo } from '../../redux/interfaces/mainData';
import { useAppDispatch, useAppSelector } from '../../redux/root/hooks';
import {
  selectFunnel,
  useFunnelColorTheme,
  useSubscriptionInfo,
} from '../../redux/slices/mainData';
import { setIsCookieBannerOpen, setLinkTreeLinks } from '../../redux/slices/ui';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { ActionsController } from './ActionsController';
import { getElementColor, normalizeHref } from '../helper';

interface PropTypes {
  imprintURL?: string;
  privacyPolicyURL?: string;
  backgroundColor?: string;
  customBrandingEnabled?: boolean;
  customBranding?: {
    label?: string;
    link?: string;
    src?: string;
  };
}

const FooterComponent = ({
  imprintURL,
  privacyPolicyURL,
  backgroundColor,
  customBrandingEnabled,
  customBranding,
}: PropTypes): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { brandingEnabled, language } = useAppSelector(selectFunnel) as Funnel;
  const subscriptionInfo = useSubscriptionInfo() as SubscriptionInfo;
  const imprintLabel = language === 'DE' ? 'Impressum' : 'Imprint';
  const privacyPolicyLabel = language === 'DE' ? 'Datenschutzerklärung' : 'Privacy policy';
  const cookieSettingsLabel =
    language === 'DE' ? 'Datenschutzeinstellungen' : 'Open data privacy settings';

  useEffect(() => {
    dispatch(
      setLinkTreeLinks([
        {
          title: imprintLabel,
          url: imprintURL ?? '',
        },
        {
          title: privacyPolicyLabel,
          url: privacyPolicyURL ?? '',
        },
      ]),
    );
  }, [imprintURL, privacyPolicyURL]);
  const theme = useFunnelColorTheme();
  return (
    <ActionsController
      style={{
        background: getElementColor(backgroundColor, theme),
      }}
    >
      <div className="footer-wrapper p-4 pb-5 pt-4 w-100 d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex mb-2">
          <a
            href={normalizeHref(imprintURL as string)}
            className="footer-wrapper__link"
            target="_blank"
            rel="noreferrer"
          >
            {imprintLabel}
          </a>
          <span className="mx-2 fw-lighter">|</span>
          <a
            href={normalizeHref(privacyPolicyURL as string)}
            className="footer-wrapper__link"
            target="_blank"
            rel="noreferrer"
          >
            {privacyPolicyLabel}
          </a>
          <span className="mx-2 fw-lighter">|</span>
          <a className="footer-wrapper__link" onClick={() => dispatch(setIsCookieBannerOpen(true))}>
            {cookieSettingsLabel}
          </a>
        </div>
        {customBrandingEnabled && subscriptionInfo?.customBrandingEnabled ? (
          <p className="footer-wrapper__branding m-0">
            <a
              href={normalizeHref(customBranding?.link as string)}
              target="_blank"
              rel="noreferrer"
            >
              {customBranding?.label}
              <img className="custom-branding-img" src={customBranding?.src} alt="" />
            </a>
          </p>
        ) : (
          brandingEnabled && (
            <p className="footer-wrapper__branding m-0">
              ⚡️ by{' '}
              <a href="https://meetovo.de/?source=cf" target="_blank" rel="noreferrer">
                MEETOVO
              </a>
            </p>
          )
        )}
      </div>
    </ActionsController>
  );
};

const FooterComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
};

FooterComponent.craft = {
  name: CRAFT_ELEMENTS.FOOTER,
  props: FooterComponentDefaultProps,
  rules: {
    isDeletable: false,
  },
  isDeletable: false,
};

export default FooterComponent;

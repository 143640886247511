import { combineReducers } from '@reduxjs/toolkit';
import userInputReducer from '../slices/userInput';
import mainDataReducer from '../slices/mainData';
import uiReducer from '../slices/ui';

export const rootReducer = combineReducers({
  userInput: userInputReducer,
  mainData: mainDataReducer,
  ui: uiReducer,
});

export default rootReducer;

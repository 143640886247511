export const getObjectId = (length: number = 16): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randS = '';

  while (length > 0) {
    randS += chars.charAt(Math.floor(Math.random() * chars.length));
    length--;
  }
  return randS;
};

import { getUniqueIdentifier } from './url';
import { getEnvironment, isCustomDomain } from './environment';

export const isLocalStorageAvailable = (): boolean => {
  const test = '__some_random_key_you_are_not_going_to_use__';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const isInIframe = (): boolean => window.location !== window.parent.location;

export const openBookingInNewTab = (): void => {
  if (isCustomDomain()) {
    const { domain } = getEnvironment();
    window.open(domain, '_blank');
  } else window.open('https://app.meetovo.de/funnel/' + getUniqueIdentifier(), '_blank');
};

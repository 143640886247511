import React from 'react';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { ActionsController } from './ActionsController';

const RichTextComponent = (props: { html: string }): React.ReactElement => {
  const { html = '' } = props;
  return (
    <ActionsController className="rich-text__wrapper">
      <div className="rich-text__inner" dangerouslySetInnerHTML={{ __html: html }}></div>
    </ActionsController>
  );
};

RichTextComponent.craft = {
  name: CRAFT_ELEMENTS.RICH_TEXT,
  props: {
    html: '',
  },
  related: {},
};

export default RichTextComponent;

import React from 'react';
import ErrorMessageWithReload from './ErrorMessageWithReload';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): { hasError: boolean } {
    console.error(error.stack);
    return { hasError: true };
  }

  render(): React.ReactElement {
    if (this.state.hasError) {
      return <ErrorMessageWithReload />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import {
  FunnelPage,
  PageTypes,
  ThirdPartyTrackingSettings,
  ThirdPartyTrackingSettingsByProvider,
} from '../redux/interfaces/mainData';
import store from '../redux/root/store';
import debounce from 'debounce';

export const sortPagesByOrder = (pages: FunnelPage[]): FunnelPage[] => {
  const copiedPages = JSON.parse(JSON.stringify(pages)) as FunnelPage[];

  // to make sure thank you page is always at the end
  const thankYouPageIndex = copiedPages.findIndex(({ type }) => type === 'THANK_YOU');
  if (thankYouPageIndex > -1) copiedPages[thankYouPageIndex].pageOrder = copiedPages.length + 1000;

  return copiedPages.sort((a, b) => a.pageOrder - b.pageOrder);
};

export const filterPagesByType = (pages: FunnelPage[] | [], type: PageTypes): FunnelPage[] => {
  return pages.filter((page) => page.type === type);
};

export const getCommonPagesSortedByOrder = (pages: FunnelPage[] | []): FunnelPage[] => {
  const filteredPages = filterPagesByType(pages, 'COMMON');
  const sortedPages = sortPagesByOrder(filteredPages);

  return sortedPages;
};

export const getThankYouPage = (pages?: FunnelPage[] | []): FunnelPage => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  const filteredPages = filterPagesByType(pages ?? funnelPages, 'THANK_YOU');

  return filteredPages[0];
};

export const getStepIndexByFunnelPageId = (id: number): number => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  const sortedFunnelPages = getCommonPagesSortedByOrder(funnelPages);
  return sortedFunnelPages.findIndex(({ id: thisId }) => id === thisId);
};

export const getThankYouPageStepIndex = (): number => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  const sortedFunnelPages = sortPagesByOrder(funnelPages);

  return sortedFunnelPages.findIndex((page) => page.type === 'THANK_YOU');
};

export const getThankYouPageId = (): number => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  return getThankYouPage(funnelPages).id;
};

export const getFunnelPageIdByStepIndex = (index: number): number => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  const sortedFunnelPages = getCommonPagesSortedByOrder(funnelPages);
  return sortedFunnelPages[index]?.id;
};

export const getThirdPartyTrackingSettingsByProvider = (
  thirdPartyTrackingSettings: ThirdPartyTrackingSettings[],
): ThirdPartyTrackingSettingsByProvider => ({
  facebookPixel: thirdPartyTrackingSettings.find(
    ({ provider }) => provider === 'facebook-pixel',
  ) ?? { enabled: false, provider: 'facebook-pixel', providerId: '' },
  facebookConversionApi: thirdPartyTrackingSettings.find(
    ({ provider }) => provider === 'facebook-conversion-api',
  ) ?? { enabled: false, provider: 'facebook-conversion-api', providerId: '' },
  googleTagManager: thirdPartyTrackingSettings.find(
    ({ provider }) => provider === 'google-tag-manager',
  ) ?? { enabled: false, provider: 'google-tag-manager', providerId: '' },
  tiktokPixel: thirdPartyTrackingSettings.find(({ provider }) => provider === 'tiktok-pixel') ?? {
    enabled: false,
    provider: 'tiktok-pixel',
    providerId: '',
  },
  linkedInEvent: thirdPartyTrackingSettings.find(
    ({ provider }) => provider === 'linkedin-event',
  ) ?? {
    enabled: false,
    provider: 'linkedin-event',
    providerId: '',
  },
});

export const setCurrentOldLeadQualifierIdToLocalStorage = debounce(
  (currentNodeIds: string[]): void => {
    const { userInput } = store.getState();
    const { oldLeadQualifier } = userInput;

    const thisOldLeadQualifier = oldLeadQualifier.find(({ craftJsNodeId }) => {
      return currentNodeIds.includes(craftJsNodeId);
    });

    if (!thisOldLeadQualifier) {
      console.error('getCurrentOldLeadQualifierId failed no oldLeadQualifier found');
      localStorage.removeItem('meetovoCurrentOldLeadQualifierId');
      return;
    }

    localStorage.setItem('meetovoCurrentOldLeadQualifierId', `${thisOldLeadQualifier.id}`);
  },
  100,
);

export const isThisComponentExistInCraftState = (
  nodes: {
    [nodeId: string]: {
      displayName: string;
    };
  },
  nodeName: string,
): Boolean => Boolean(Object.keys(nodes).find((key) => nodeName === nodes?.[key]?.displayName));

export const doesPageExist: (actionLogic: string | number) => boolean = (
  actionLogic: string | number,
) => {
  const { mainData } = store.getState();
  const { funnelPages } = mainData;
  const foundPage = funnelPages.find((page) => page.id === actionLogic);
  return Boolean(foundPage);
};

export const checkType: (value: any, type: string) => boolean = (value: any, type: string) => {
  // eslint-disable-next-line valid-typeof
  return typeof value === type;
};

export const getLocalStorageData = (key: string): any => {
  const localStorageData = localStorage.getItem(key);
  return JSON.parse(localStorageData ?? '{}');
};

export const setLocalStorageData = (key: string, data: any): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

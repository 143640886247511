import { AuthorisationData } from '../utils/interfaces';

export const setAuthDataToLocalStorage = (authData: AuthorisationData): void => {
  localStorage.setItem('meetovoFunnelAuthData', JSON.stringify(authData));
};

export const getAuthDataFromLocalStorage = (): AuthorisationData => {
  const localAuthData = localStorage.getItem('meetovoFunnelAuthData');

  if (!localAuthData)
    return {
      token: null,
      trackingId: null,
      subscriptionStatus: null,
    };

  return JSON.parse(localAuthData);
};

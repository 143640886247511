import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import IconProvider from './IconProvider';

const ErrorMessageWithReload = (): JSX.Element => {
  return (
    <Alert variant="warning">
      <Alert.Heading>Maximale Anzahl an Verbindungen erreicht</Alert.Heading>
      <p>Es gab einen Fehler beim Laden. Bitte versuche es erneut.</p>
      <Button onClick={() => window.location.reload()} variant="outline-primary">
        <IconProvider name="FiRefreshCw" /> Neu laden
      </Button>
    </Alert>
  );
};

export default ErrorMessageWithReload;

import { getEnvironment } from '../../helper/environment';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

let graphQlLink = createUploadLink({
  uri: getEnvironment().graphqlEndpoint,
  headers: {
    'keep-alive': 'true',
  },
});

export const getApolloClient = (token: string | null): ApolloClient<NormalizedCacheObject> => {
  if (token) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    graphQlLink = authLink.concat(graphQlLink);
  }

  const client = new ApolloClient({
    link: graphQlLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  window.apolloClient = client;

  return client;
};

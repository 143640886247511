export const isMobileDevice = (): boolean => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
    // @ts-expect-error
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const detectChrome = (): boolean =>
  navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');

export const isIphoneInstagram = (): boolean => {
  const ua = navigator.userAgent || navigator.vendor;

  if (ua.includes('Instagram') && /iphone|ipod|iPhone|iPod/.test(ua)) return true;

  return false;
};

const elementsToExclude = ['button', 'submit'];
const isInScope = (type: string): boolean => !elementsToExclude.includes(type) && isMobileDevice();
const setKeyboardState = (state: string): void => {
  const appRoot = document.getElementById('root');
  if (appRoot) appRoot.setAttribute('keyboard-open', state);
};

let focusInInitiated = false;
let focusOutInitiated = false;

export const initKeyboardListeners = (onKeyboardStateChange?: any): void => {
  if (!focusInInitiated) {
    document.addEventListener('focusin', (e) => {
      // @ts-expect-error
      const { type } = e.target;
      if (isInScope(type)) {
        setKeyboardState('true');
        if (onKeyboardStateChange)
          setTimeout(() => {
            onKeyboardStateChange(true);
          }, 500);
      }
    });

    focusInInitiated = true;
  }

  if (!focusOutInitiated) {
    document.addEventListener('focusout', (e) => {
      // @ts-expect-error
      const { type } = e.target;
      if (isInScope(type)) {
        setKeyboardState('false');
        if (onKeyboardStateChange)
          setTimeout(() => {
            onKeyboardStateChange(false);
          }, 500);
      }
    });

    focusOutInitiated = true;
  }
};

export const listenToResizeEnd = (onResizeEnd: Function): void => {
  let rtime: any;
  let timeout = false;
  const delta = 200;
  window.addEventListener('resize', function () {
    rtime = new Date();
    if (!timeout) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend(): void {
    if (+new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      onResizeEnd();
    }
  }
};

interface GetIpAndUserAgentCallbackInterface {
  clientIp: string;
  clientUserAgent: string;
}

let savedIpAndUserAgent: GetIpAndUserAgentCallbackInterface;
export const getIpAndUserAgent = async (): Promise<GetIpAndUserAgentCallbackInterface> => {
  return await new Promise((resolve, reject) => {
    const emptySavedIpAndUserAgent = { clientIp: '', clientUserAgent: '' };

    try {
      if (savedIpAndUserAgent) {
        resolve(savedIpAndUserAgent);
        return;
      }

      fetch('https://extreme-ip-lookup.com/json')
        .then(async (res) => await res.json())
        .then((data) => {
          resolve({ clientIp: data?.query, clientUserAgent: navigator.userAgent });
        })
        .catch(console.error);
    } catch (error) {
      resolve(emptySavedIpAndUserAgent);
    }
  });
};

export const isIphoneFacebook = (): boolean => {
  const ua = navigator.userAgent || navigator.vendor;

  if (ua.includes('FBAN') && /iphone|ipod|ipad|iPhone|iPod|iPad/.test(ua)) return true;

  return false;
};
